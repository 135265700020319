import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import ThemeProvider from '../../providers/Theme'
import * as Mui from "@mui/material";
import DisplayCard from './DisplayCard';
import { ServerURL } from '../../utils/Config';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { chartsGridClasses } from '@mui/x-charts/ChartsGrid';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '20vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '4rem',
  },
  text: {
    marginTop: theme.spacing(1),
  },
}));


export default function HQIndex() {
    const lineInfo = ['30d', '60d', '180d']
    const title = {
        '30d':'必乐量子基准户净值曲线（30天）', '60d':'必乐量子基准户净值曲线（60天）', '180d':'必乐量子基准户净值曲线（180天）'
    }
  
    const [lineData, setLineData] = useState();
    const [indexData, setIndexData] = useState();
    const [currentLine, setCurrentLIne] = useState(0);

    const fetchLineData = async () => {
        axios(ServerURL+`/hq/market/lines`).then((response) => {
            if (response.data.success) {
                setLineData(response.data.data);
            }
          })
      };
      const fetchIndexData = async () => {
        axios(ServerURL+`/hq/market/index`).then((response) => {
            if (response.data.success) {
                setIndexData(response.data.data);
            }
          })
      };

    useEffect(() => {
            fetchLineData();
            fetchIndexData();
            const nextLineInterval = setInterval(() => {
                setCurrentLIne((currentLine) => currentLine + 1);
            }, 30 * 1000);
            const updateLineDataInterval = setInterval(() => {
                fetchLineData();
            }, 3 * 60 * 1000);
            const updateIndexDataInterval = setInterval(() => {
                fetchIndexData();
            }, 30 * 60* 1000);
            return () => {
                clearInterval(nextLineInterval);
                clearInterval(updateLineDataInterval);
                clearInterval(updateIndexDataInterval);
            };
            
        }, []);




  return (
    <>
    {lineData && indexData &&
    <ThemeProvider>
<Mui.Grid container spacing={0}  minWidth={'100%'} height={'100vh'} style={{backgroundColor:'F5F5F5'}} >
    <Mui.Box   component="img" backgroundColor="#F8674D" justifyContent="center" alignItems="center"  src="/logo_v2_rev.png"  
        alt="image"  flexItem style={{ alignSelf: 'center' }}
        sx={{  position: 'absolute', left: '0vw',  top: '0vh' ,borderRight: 2, borderBottom: 2, borderColor:'#F5F5F5'}}  
        width="40vw" 
        />
    
    <Mui.Box  backgroundColor='white' width="40vw" height="17vh" sx={{  position: 'absolute', left: '0vw',  top: '23vh',border: 2, borderColor:'#F5F5F5' }}>
        <Mui.Grid height={'16vh'} container spacing={3} px={4} pb={4} pt={6} justifyContent="center" alignItems="center" wrap="nowrap">
        <Mui.Typography variant="h3" sx={{fontWeight: 500, fontSize: 50 ,fontFamily: 'STZhongsong'}}>
                市场指数榜
            </Mui.Typography>
         
            
            <Mui.Divider orientation="vertical" variant="middle" flexItem sx={{ ml:'30px' }} />
            <Mui.Stack pl={6} direction="column">
            <Mui.Stack  direction="row">
                <Mui.Typography sx={{fontWeight: 200, fontSize: 30, color: '#F8674D', fontFamily: 'STZhongsong' }}>
                    量子+
                </Mui.Typography>
                <Mui.Typography sx={{fontWeight: 200, fontSize: 30 ,fontFamily: 'STZhongsong'}}>
                    赋能算法
                </Mui.Typography>
            </Mui.Stack>
                
            <Mui.Typography   mt={0} sx={{fontWeight: 100, fontSize: 20 ,fontFamily: 'STZhongsong'}}>
             专注先进量化策略研究
            </Mui.Typography>
         </Mui.Stack>
         
        </Mui.Grid>

        <Mui.Box backgroundColor="#F8674D" width='15vw' height='4px'
         sx={{  position: 'absolute', left: '12.5vw' ,  top: '13vh' }}
        />
    </Mui.Box>

    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '0vw',  top: '40vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
        <DisplayCard unit={indexData?indexData[0].unit:null}
         string={indexData?indexData[0].symbol:null}
         value={indexData?indexData[0].value:null}
         change={indexData?indexData[0].change:null} />
    </Mui.Box>


    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '20vw',  top: '40vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
        <DisplayCard unit={indexData?indexData[1].unit:null}
         string={indexData?indexData[1].symbol:null}
         value={indexData?indexData[1].value:null}
         change={indexData?indexData[1].change:null} />
    </Mui.Box>

    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '0vw',  top: '55vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
        <DisplayCard unit={indexData?indexData[2].unit:null}
         string={indexData?indexData[2].symbol:null}
         value={indexData?indexData[2].value:null}
         change={indexData?indexData[2].change:null} />
    </Mui.Box>


    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '20vw',  top: '55vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
        <DisplayCard unit={indexData?indexData[3].unit:null}
         string={indexData?indexData[3].symbol:null}
         value={indexData?indexData[3].value:null}
         change={indexData?indexData[3].change:null} />
    </Mui.Box>

    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '0vw',  top: '70vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
        <DisplayCard unit={indexData?indexData[4].unit:null}
         string={indexData?indexData[4].symbol:null}
         value={indexData?indexData[4].value:null}
         change={indexData?indexData[4].change:null} />
    </Mui.Box>


    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '20vw',  top: '70vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
        <DisplayCard unit={indexData?indexData[7].unit:null}
         string={indexData?indexData[7].symbol:null}
         value={indexData?indexData[7].value:null}
         change={indexData?indexData[7].change:null} />
    </Mui.Box>

    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '0vw',  top: '85vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
       <DisplayCard unit={indexData?indexData[6].unit:null}
         string={indexData?indexData[6].symbol:null}
         value={indexData?indexData[6].value:null}
         change={indexData?indexData[6].change:null} />
    </Mui.Box>


    <Mui.Box  display="flex"  width={'20vw'} height={'15vh'}  
        sx={{  backgroundColor:'white',  position: 'absolute', left: '20vw',  top: '85vh',border: 3, borderColor:'#F5F5F5'}} 
        justifyContent="center" alignItems="center">
       <DisplayCard unit={indexData?indexData[5].unit:null}
         string={indexData?indexData[5].symbol:null}
         value={indexData?indexData[5].value:null}
         change={indexData?indexData[5].change:null} />
    </Mui.Box>
   
    <Mui.Box  display="flex"  width={'60vw'} height={'65vh'}  
    sx={{  backgroundColor:'white',  position: 'absolute', left: '40vw',  top: '0vh',border: 3, borderColor:'#F5F5F5'}} 
    justifyContent="center" alignItems="center">
        <Mui.Stack direction="column" justifyContent="center" alignItems="center">
            <Mui.Typography  variant="h4" ml={-2} mb={-5} sx={{fontWeight: 400 ,fontFamily: 'STZhongsong'}}>
             {lineData[currentLine%lineData.length].symbol + '近期走势曲线'}
            </Mui.Typography>
            
                {lineData ? 
                <LineChart
                    xAxis={[{ 
                    id: 'time',
                    data: lineData[currentLine%lineData.length]['time'].map(i=> (new Date(i))),
                    scaleType: 'time',
                    valueFormatter:  (date) =>
                                date?.toLocaleDateString('zh-cn', {
                                  year: '2-digit',
                                  month: '2-digit',
                                  day: '2-digit',
                                }),
                    max: new Date(lineData[currentLine%lineData.length].time[0]),
                    min: new Date(lineData[currentLine%lineData.length].time[lineData[currentLine%lineData.length].time.length-1]),
                    }]}
                    
                    yAxis={[
                    { 
                        min: Math.min.apply(Math, lineData[currentLine%lineData.length]['k-line'])*0.89,
                        max: Math.max.apply(Math, lineData[currentLine%lineData.length]['k-line'])*1.11,
                        id: 'value'
                    },
                    ]}
                    leftAxis={{
                    axisId : 'value',
                    disableLine : true,
                    disableTicks: true,
                    tickFontSize: 20,
                    tickLabelStyle: {
                        transform: `translateX(-15px)`
                    },
                    
                    }}
                    bottomAxis={{
                    axisId : 'time',
                    tickFontSize: 20,
                    tickSize:0,
                    tickLabelStyle: {
                        transform: `translateY(20px)`
                    },
                    }}
                    margin={{
                        
                        left: 80
                      }}
                    grid={{ horizontal: true }}
                    sx={{
                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                        transform: 'translateX(-10px)',
                    },
                    [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 3 },
                    '& .MuiLineElement-root': {
                        strokeWidth: 4,
                    },
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                        stroke:"#696969",
                        strokeWidth:4
                        },
                    '& .MuiAreaElement-series-vps': {
                        fill: "url('#gradient')",
                    },
                    }}
                    series={[
                    {
                        id: 'vps',
                        data: lineData[currentLine%lineData.length]['k-line'],
                        color: (
                            parseFloat(lineData[currentLine%lineData.length]['k-line'][0]) >
                            parseFloat(lineData[currentLine%lineData.length]['k-line'][lineData[currentLine%lineData.length]['k-line'].length-1]) ?
                            'red':
                            'green'
                        ),
                        area: true,
                        showMark: false,
                    },
                    ]}
                    width={window.innerWidth * 0.5}
                    height={window.innerWidth * 0.3}
                    slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'top', horizontal: 'left' },
                        padding: {left: 30, bottom: 10},
                    },
                    }}>
                    <defs>
                        <linearGradient id="gradient" gradientTransform="rotate(0)">
                        <stop offset="5%" stopColor="#ffffff55" />
                        <stop offset="95%" stopColor={parseFloat(lineData[currentLine%lineData.length]['k-line'][0]) >
                            parseFloat(lineData[currentLine%lineData.length]['k-line'][lineData[currentLine%lineData.length]['k-line'].length-1]) ?
                            '#ff676755':
                            '#66b26655'} />
                        </linearGradient>
                    </defs>
                    </LineChart>
                :<></>}

         </Mui.Stack>
    </Mui.Box>

    <Mui.Box  display="flex"  width={'60vw'} height={'35vh'}  
    sx={{  backgroundColor:'white',  position: 'absolute', left: '40vw',  top: '65vh',border: 3, borderColor:'#F5F5F5'}} 
    justifyContent="center" alignItems="center">
        <Mui.Stack direction="column" justifyContent="center" alignItems="center">
        <Mui.Typography  variant="h4" ml={-2} mb={3} sx={{fontWeight: 400 ,fontFamily: 'STZhongsong'}}>
             逐日收益率
            </Mui.Typography>
                {lineData &&
            <BarChart
                grid={{ horizontal: true }}
                series={[{data: lineData?lineData[currentLine%lineData.length]['change'].reverse():null}]}
                margin={{
                  top: 10,
                  bottom: 20,
                  left: 80
                }}
                yAxis={[
                  {
                    id:'value',
                    colorMap: {
                      type: 'piecewise',
                      thresholds: [0],
                      colors: ['green', 'red'],
                    }
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: lineData[currentLine%lineData.length]['time'].map(i=> (new Date(i))),
                    valueFormatter:  (date) =>
                        date?.toLocaleDateString('zh-cn', {
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                        }),
                  },
                ]}
                leftAxis={{
                    axisId : 'value',
                    disableLine : true,
                    disableTicks: true,
                    tickFontSize: 20,
                    tickLabelStyle: {
                        transform: `translateX(0px)`
                    },
                    valueFormatter :(value)=> (value * 100+'%')
                    }}
                bottomAxis={null}
                sx={{
                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                        transform: 'translateX(30px)',
                    },
                    [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 1 },
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                 
                    '& .MuiAreaElement-series-vps': {
                        fill: "url('#gradient')",
                    },
                    }}
                width={window.innerWidth * 0.5} 
                height={window.innerWidth * 0.12}
              />
                }

         </Mui.Stack>
    </Mui.Box>

   
</Mui.Grid>

</ThemeProvider>
  }  
</>
  );
}

